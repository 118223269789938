import * as React from "react"

import Layout from "src/components/layout"
import Seo from "src/components/seo"
import ContactButton from "src/components/common/contact_button";
import HolidayNotice from "../components/common/holiday_notice";

const Contact = () => (
  <Layout>
    <Seo title="お問い合わせ" isIndex={true}/>

    <section className="container article-list-container">
      <HolidayNotice/>
      <h1 itemProp="headline">お問い合わせ</h1>

      <p className="text-left">
        下記「お問い合わせ」ボタンよりご連絡ください。
        お問い合わせ内容に関してはすべて目を通した上で対応いたします。
      </p>
      <p className="text-left">
        なお、内容によっては対応できない場合もございます。あらかじめご了承ください。
      </p>
      <br/>
      <ContactButton/>
    </section>
  </Layout>
)

export default Contact
